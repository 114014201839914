.App {
  text-align: center;
  width: 100vw;
}

a{
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6{
  font-family: Helvetica;
  font-weight: normal;
}

.text-title{
  font-weight: lighter;
}

p{
  line-height: 20pt;
}