.posters-mainDiv{
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
}

.poster-explaination{
    width: 35%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    right: 0;
    z-index: 99;
}

.text-poster-fix{
    width: inherit;
    height: 100vh;
    position: fixed;
    background-color: #ededed;
    right: 0;
}

.poster-text{
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    color: #333333;
}

.poster-text>p{
    padding-left: 40px;
    padding-right: 40px;

}

.poster-title{
    color: #007C9E;
}

.posters-foto{
    padding-top: 100px;
    width: 65%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.poster-foto{
    width: 60%;
    margin-bottom: 20px;
}

.text-big-hide{
    display: none;
    margin-bottom: 40px;
    width: 75%;
}


@media only screen and (max-width: 1025px){
    .posters-mainDiv{
        flex-direction: column-reverse;
        justify-content: flex-end;
        align-items: center;
        height: auto;
    }

    .poster-explaination{
        width: 100%;
        display: none;
    }

    .poster-text{
        position: unset;
        margin-bottom: 50px;
        color: #333333;
    }

    .posters-foto{
        width: 100%;
    }

    .text-poster-fix{
        height: unset;
        position: unset;
        background-color: unset;
    }

    .text-big-hide{
        display: block;
    }

    .news-foto{
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 813px){
    .news-foto{
        width: 80%;
    }
}