.contacts{
    align-self: center;
    width: 200px;
    margin-top: 150px;
    color: #7300bf;
    border-width: 5px;
    border-style: solid;
    border-image: 
    linear-gradient(
      to bottom left, 
      #0098A8, 
      #7300bf
    ) 5 ;
    padding: 5px;
}

.contactmainDiv{
    width: 100vw;
    min-height: 100vh;
    background-image: url('../image/contact-bg.png');
    background-size: 100vh;
    background-repeat: no-repeat;
    background-position-y: bottom ;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: #333333;
    z-index: 70;
}

.contactmainDiv>p{
    align-self: center;
}

.contact-div1{
    width: 65%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.contact-div2{
    width: 35%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: #333333;
    z-index: 99;
}

.contact-table{
    color: #333333;
    width: 50%;
}

.contableTitle{
    width: 20%;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 10px;
}

.contableData{
    width: 60%;
    padding-top: 20px;
    padding-bottom: 10px;

}

.contact-table>tr>td{
    border-bottom: 0.5px #333333 solid;

}

.contact-table-a{
    color: #333333;
}

.contact-table-a:hover{
    color: #7300bf;
}

.contact-contact-icondiv2{
    width: 80%;
    margin-top: 10px;
}

.contact-coniconpic2{
    width: 50px;
    background-color: #94949459;
    border-radius: 50%;
    padding: 3px;
    margin: 5px;
    /* background-image: linear-gradient(
        to bottom left, 
        #7300bf,
        #0098A8
        ); */
}

.contact-coniconpic2:hover{
    /* border-radius: 50%; */
    /* background-color: #333333; */
    /* border: 3px solid rgb(128, 128, 128); */

    background-image: linear-gradient(
        to bottom left, 
        #7300bf,
        #0098A8
        );
}

.apic1{
    animation: 0.5s ease-out 0s 1 slideInFromRight;
    /* animation: 3s 0s infinite bg-change; */
    /* animation-fill-mode: both; */
}
.apic2{
    animation: 0.7s ease-out 0s 1 slideInFromRight;
    /* animation: 3.2s 0s infinite bg-change; */
}
.apic3{
    animation: 0.9s ease-out 0s 1 slideInFromRight;
    /* animation: 3.4s 0s infinite bg-change; */
}
.apic4{
    animation: 1.1s ease-out 0s 1 slideInFromRight;
    /* animation: 3.6s 0s infinite bg-change; */
}
.apic5{
    animation: 1.3s ease-out 0s 1 slideInFromRight;
    /* animation: 3.8s 0s infinite bg-change; */
}

.smartphone{
    width: 400px;
    /* margin-right: 100px; */
}

@keyframes slideInFromRight{
    0%{
        transform: translateY(500%);
        background-color: gray;
    }
    50%{
        /* transform: translateY(250%); */
        background-image: linear-gradient(
        to bottom left, 
        #7300bf,
        #0098A8
        );
    }
    100%{
        transform: translateY(0);
        /* background-color: gray; */
        background-image: linear-gradient(
        to bottom left, 
        #7300bf,
        #0098A8
        );
    }
}

@keyframes bg-change{
    0%{
        background-color: gray;
    }
    50%{
        background-image: linear-gradient(
        to bottom left, 
        #7300bf,
        #0098A8
        );
    }
    100%{
        background-color: gray;
    }

}

@media only screen and (max-width: 1025px){
    .contactmainDiv{
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        background-size: 100vw;
    }

    .contact-div1{
        width: 100%;
        height: auto;
    }

    .contact-div2{
        width: 100%;
        height: auto;
        background-color: unset;
    }

    .contacts{
        margin-top: 200px;
    }

    .contact-contact-icondiv2{
        width: 100%;
        margin-top: 100px;
    }

    .contact-coniconpic2{
        margin-bottom: 50px;
        background-color: #333333;
    }

    .smartphone{
        width: 400px;
        margin-right: unset;
        margin-bottom: 400px;
    }

}

@media only screen and (max-width: 767px){
    .contactmainDiv{
        background-size: 650px;
    }

    .contact-table{
        width: 80%;
    }

    .apic1{
        animation: unset;
    }
    .apic2{
        animation: unset;
    }
    .apic3{
        animation: unset;
    }
    .apic4{
        animation: unset;
    }
    .apic5{
        animation: unset;
    }

    .smartphone{
        width: 300px;
        margin-right: unset;
        margin-bottom: 400px;
    }

}